import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import Config from "../../config";
import ApplianceTypes from "./appliance-types";
import AnswerAdvisorFooter from "../answer-adviser/AnswerAdviserFooter";
import ApplianceService from "./Service";
import AI from "../../appInsights"
import AnswerAdviser from "../../modules/answer-adviser";
import util from "../../components/util";
import DashboardService from '../dashboard/Service';

const titleMapping = {
  "ONLINE": "Connected Online",
  "OFFLINE": "Connected Offline",
  "READY": "Connected Ready",
  "CAPABLE": "Connected Capable"
};

export default ReactClass({
  displayName: "DeviceList",
  propTypes: {
    appliances: PropTypes.array,
    onApplianceSelected: PropTypes.func.isRequired,
    selectedDeviceId: PropTypes.string,
    selectedSerial: PropTypes.string
  },

  getInitialState() {
    return {
      applListSidePanel: false,
      showInput: false,
      serialNo: "",
      isLoading: false,
    };
  },

  selectAppliance(selectedIndex, selectedDevice = "", selectedAppliances = []) {
        let appliances;
        if(selectedDevice === "NewDevice") {
          appliances = selectedAppliances;
        } else {
          appliances = this.props.appliances;
        }
        const selectedDeviceId = appliances[selectedIndex].deviceId,
        selectedSerial = appliances[selectedIndex].serial;
        const userSessoinId = util.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
            applianceSessionID = sessionStorage.getItem("applianceSessionID");
        let param = AnswerAdviser.getCRMTicket();
        const {CRM_TICKET_ID} = param;
        const applianceSessionEventData = {model: appliances[selectedIndex].model,
          serial: appliances[selectedIndex].serial, softwareVersions: JSON.stringify(appliances[selectedIndex].versions), 
          pumaVersion: appliances[selectedIndex].pumaVersion,
          userSessoinId, applianceSessionID,
          CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : ""};
        if(!appliances[selectedIndex].pumaVersion){
          DashboardService.fetchGroupDetails(appliances[selectedIndex]).then((groupDetails)=>{
            this.sendApplianceSessionEvent({
              ...applianceSessionEventData,
              firmwareVersion: groupDetails.FirmwareVersion
            });
          })
        }
        else{
          this.sendApplianceSessionEvent(applianceSessionEventData);
        }
    ApplianceService.setScrollTopOfApplianceList(this.applianceList.scrollTop),
    this.props.onApplianceSelected(selectedDeviceId, selectedSerial);
  },

  sendApplianceSessionEvent(data){
    AI.trackUserLoginEvent("APPLIANCE_SESSION", data);
  },

  renderApplianceRows() {
    const {appliances = [], selectedDeviceId, selectedSerial} = this.props;
    let selectedIdx = "";
    if(selectedDeviceId)
      selectedIdx = appliances.map(e => e.deviceId).indexOf(selectedDeviceId);
    else
      selectedIdx = appliances.map(e => e.serial).indexOf(selectedSerial);

    if(selectedIdx == -1)
      selectedIdx = 0;
    return appliances.map((a, i) => {
      const {model: label = "unknown", connectedStatus = ""} = a;

      return (
        <li key={a.deviceId + i}
            className={selectedIdx == i ? "selected": ""}
            onClick={this.selectAppliance.bind(this, i)}>
          <img src={`${Config.baseDir}images/${ApplianceTypes.getType(a.type)}_icon.svg`}
              alt="Icon" />
          <span title={`Serial: ${a.serial ? a.serial : "N/A"}`}>{a.model ? label : "N/A"}</span>
          {(!connectedStatus || connectedStatus === "UNAVAILABLE"
              ? null
              : <i className={`icon icon-wifi_icon conn-status ${connectedStatus}`} title={titleMapping[connectedStatus]}></i>)}
        </li>
      );
    });
  },
  storeSerialNo(event) {
    this.setState({
      serialNo: event.target.value
    });
  },

  setNewAppliance() {
    const {application: app} = this.props,
    {notifications} = app;
    this.setState({
      isLoading: true
    })
    let retVal = {
      serialNo: [this.state.serialNo]
    };
    let Appliances = ApplianceService.getCurrentApplianceInfos();
    let serialArray = [];
    Appliances.map((value) => {
      serialArray.push(value.serial);
    })
    if(!serialArray.includes(this.state.serialNo)) {
      let applianceInfoPromise = ApplianceService.getApplianceInfo(retVal, true);
      applianceInfoPromise.then(ApplianceService.modifyAppliances)
      .then((res) => {
        const selectedAppliances = [... Appliances, ...res];
        ApplianceService.setCurrentApplianceInfos(selectedAppliances);
        this.selectAppliance(selectedAppliances.length - 1, "NewDevice", selectedAppliances)
      }).catch(errRes => {
        notifications.error(`Serial Number : ${this.state.serialNo} is not compatible with SubZero Connected Portal`);
        this.setState({
          isLoading: false
        })
      });
    } else {
        setTimeout(() => {
            this.setState({
                isLoading: false
              })
            notifications.error(`Device with Serial Number ${this.state.serialNo} is already added`);
          }, 1000);
        }
  },

  render() {
    const appliances = this.renderApplianceRows(),
        content = appliances.length ? appliances : <li className="empty">No Appliances</li>;
        const { showInput, serialNo, isLoading } = this.state;
    return (
      <div className="appliance-list-container">
        <ul ref={x => this.applianceList = x}
          className="list appliance-list">
          {content}
          {showInput &&
            <li
              style={{
                height: "46px",
                overflow: "hidden"
              }}
            >
              <input ref="serialNoInput" values={serialNo}
                onChange={this.storeSerialNo}
                placeholder="Serial Number"
                style={{
                  height: "36px",
                }}
              />
              <button style={{
                marginLeft: "2%",
                width: "17%",
                height: "36px"
              }}
              onClick={() => {
                this.setNewAppliance();
              }}
              disabled={!serialNo ? true : isLoading}
              >{!isLoading ? <img src={`${Config.baseDir}images/tick_icon.svg`} alt="Icon" /> : <i className="anim icon icon-loader spin"></i> }</button>
            </li>
          }
          {!showInput &&
            <li>
              <button onClick={() => {
                this.setState({
                  showInput: !showInput
                })
              }} style={{ backgroundColor: "#53565A", marginBottom: "0px"}}><img src={`${Config.baseDir}images/plus_icon.svg`}
              alt="Icon" style={{
                width: "10px"
              }} /></button>
            </li>
          }
        </ul>
        <AnswerAdvisorFooter />
      </div>
    );
  },
  componentDidMount() {
    const {appliances = [], selectedDeviceId} = this.props,
        scrollTop = ApplianceService.getScrollTopOfApplianceList(),
        selectedIdx = appliances.map(e => e.deviceId).indexOf(selectedDeviceId),
        clientHeightOfFirstNode = this.applianceList.children[0].clientHeight;

    this.applianceList.scrollTop = scrollTop || (selectedIdx * clientHeightOfFirstNode);
  }
});
